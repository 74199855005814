<template>
  <div class="Site PageBox">
    <van-nav-bar
            fixed
            :border="false"
            :title="$t('teamReport.default[0]')"
            left-arrow @click-right="onClickRight"
            @click-left="$router.go(-1)"
    >
      <div slot="right">
        <van-button color="#F0B80D" size="min" style="width: 100px;height: 26px" @click="onClickRight">
          <span>{{$t('common7[5]')}}</span></van-button>
      </div>
    </van-nav-bar>
    <div style="display: flex">
      <div class="Menu2item1">
        <div style="color: #ccc">{{$t('common7[6]')}}
        </div>
        <div style="color: #e7e7e7"> {{ teamCount }} </div>
      </div>
      <div class="Menu2item1">
        <div style="color: #ccc">{{$t('common7[7]')}}
        </div>
        <div style="color: #e7e7e7"> {{ monthNew }} </div>
      </div>
    </div>
    <div class="ScrollBox" style="color: #ccc">
      <div v-for="(item,index) in listData"
           style="margin-left: 10px;background-color: #252D30;padding: 7px;margin-right: 10px">
          <div>{{$t('userInfo.default[2]')}}: {{item['username']}}</div>
        <div>{{$t('bindAccount.fields[4]')}}: {{item['u_type']}}</div>
        <div>{{$t('common7[8]')}}: {{item['reg_time']}}</div>
        <div>{{$t('common7[9]')}}: {{item['mcount']}}</div>
      </div>
      <div v-if="listData.length==0" style="color: #fff;margin: 20px auto;margin-left: 20px">{{$t('vanPull[1]')}}</div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'Service',
    components: {

    },
    props: {},
    data() {
      return {
        teamCount:0,
        monthNew:0,
        listData:[],
      };
    },
    computed: {

    },
    watch: {

    },
    created() {

    },
    mounted() {
      this.getListData();
    },
    activated() {

    },
    destroyed() {

    },
    methods: {
      onClickRight(){
        var url = "/user/invite/"+this.UserInfo.idcode+"/"+this.UserInfo.idcode
        this.$router.push(url);
      },
      getListData(type) {
        var json = {};
        this.$Model.TeamReport(json,(data) => {
          this.isLoad = false;
          if (data.code == 1) {
            var users = data.data;
            this.monthNew = data.monthNew;
            this.teamCount = users.length
            users.forEach(uu=>{
              var ut = uu['user_type'];
              var utype = this.$t("busAuth3["+ut+"]")
              uu['u_type'] = utype
            })
            this.listData = users;
          } else {
            this.listData = [];
            this.isFinished = true;
          }
        })
      }
    },
  }
</script>
<style scoped>
  .PageBox {
    color: #635327;
    background-color: #13171A;
  }

  .van-nav-bar {
    background-color: #191C23;
  }
  .van-nav-bar>>>.van-nav-bar__title{
    color:#cccccc;
    font-weight: bold;
  }


  .Menu2item1 {
    margin-top: 10px;
    background-color: #191C23;
    line-height: 25px;
    margin-left: 10px;
    width: 48%;
    text-align: center;
    padding: 10px;
  }

  .ScrollBox{
    background-color: #13171A;
  }
</style>